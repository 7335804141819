<template>
<div>
  <el-steps :space="200" :active="step" finish-status="success" align-center class="is-underline">
    <el-step>
      <template v-slot:title="scope">
        <span :class="canGoToStep(0) ? 'cursor-pointer' : ''" @click="goToStep(0)">
          <i class="el-icon-arrow-right" v-if="canGoToStep(0)"></i>
          Company Detail
        </span>
      </template>
    </el-step>
    <el-step>
      <template v-slot:title="scope">
        <span :class="canGoToStep(1) ? 'cursor-pointer' : ''" @click="goToStep(1)">
          <i class="el-icon-arrow-right" v-if="canGoToStep(1)"></i>
          Contacts
        </span>
      </template>
    </el-step>
    <el-step>
      <template v-slot:title="scope">
        <span :class="canGoToStep(2) ? 'cursor-pointer' : ''" @click="goToStep(2)">
          <i class="el-icon-arrow-right" v-if="canGoToStep(2)"></i>
          Upload Document
        </span>
      </template>
    </el-step>
    <el-step >
      <template v-slot:title="scope">
        <span :class="canGoToStep(3) ? 'cursor-pointer' : ''" @click="goToStep(3)">
          <i class="el-icon-arrow-right" v-if="canGoToStep(3)"></i>
          Set Up Charge Code
        </span>
      </template>
    </el-step>
    <el-step>
      <template v-slot:title="scope">
        <span :class="canGoToStep(4) ? 'cursor-pointer' : ''" @click="goToStep(4)" @dblclick="debugMode()">
          <i class="el-icon-arrow-right" v-if="canGoToStep(4)"></i>
          Preview
        </span>
      </template>
    </el-step>
  </el-steps>

  <div v-loading="isLoading">
    <div class="my-2" v-if="step === 0">
      <el-form :model="row" :rules="rules" ref="mainForm" class="compact">
      <b-row>
        <b-col lg="6">
          <el-form-item :label="$t('Company Name (Invoice)')" prop="companyName">
            <el-input size="small" v-model="row.companyName" maxlength="255"/>
          </el-form-item>

          <el-form-item :label="$t('Company Name (System)')" prop="companyNameInternal">
            <el-input size="small" v-model="row.companyNameInternal" maxlength="255"/>
          </el-form-item>

          <el-form-item :label="$t('Company Address')" prop="companyAddress">
            <el-input size="small" v-model="row.companyAddress" maxlength="255"/>
          </el-form-item>

          <el-form-item :label="$t('BR Number (For HK Company)')" prop="brNumber">
            <el-input size="small" v-model="row.brNumber" maxlength="32"/>
          </el-form-item>

          <el-form-item :label="$t('Company Type')" prop="companyType">
            <div class="mt-2">
              <el-radio v-model="row.companyType" label="External">External</el-radio>
              <el-radio v-model="row.companyType" label="InterCom">InterCom</el-radio>
            </div>
          </el-form-item>

          <el-form-item :label="$t('Payment Terms (Days)')" prop="paymentTermDays">
            <el-input size="small" v-model="row.paymentTermDays" type="number" min="0" max="3650"/>
          </el-form-item>

          <el-form-item :label="$t('Key Account')" prop="keyAccount">
            <el-input size="small" v-model="row.keyAccount" maxlength="255"/>
          </el-form-item>

          <el-form-item :label="$t('Credit Amount')">
            <el-input size="small" v-model="row.creditAmount"/>
          </el-form-item>

          <el-form-item :label="$t('Contract End Date')">
            <el-input size="small" v-model="row.contractEndDate" placeholder="YYYY-MM-DD"/>
          </el-form-item>

          <el-form-item :label="$t('Contract Inactive')">
            <div style="clear: both">
              <el-checkbox size="small" v-model="row.contractEndInactive"/>
            </div>
          </el-form-item>

          <el-form-item :label="$t('Service Code')" prop="serviceCode">
            <div style="clear: both">
              <el-select size="mini" v-model="row.serviceCode" readonly="readonly">
                <el-option v-for="item in serviceCodeOptions" :key="item" :label="item" :value="item"/>
              </el-select>
            </div>
          </el-form-item>
        </b-col>
        <b-col lg="6">
          <el-form-item :label="$t('ERP Account Code')" prop="accountCode">
            <el-input size="small" v-model="row.accountCode" maxlength="32"/>
          </el-form-item>

          <el-form-item :label="$t('Country Code')" prop="country">
            <el-input size="small" v-model="row.country" maxlength="4"/>
          </el-form-item>

          <el-form-item :label="$t('Corporate Registration Number')" prop="corporateRegistrationNumber">
            <el-input size="small" v-model="row.corporateRegistrationNumber" maxlength="64"/>
          </el-form-item>

          <el-form-item :label="$t('Contract Type')" prop="contractType">
            <el-input size="small" v-model="row.contractType" maxlength="32"/>
          </el-form-item>

          <el-form-item :label="$t('Default Currency Code')" prop="paymentCurrency">
            <el-input size="small" v-model="row.paymentCurrency" maxlength="3"/>
          </el-form-item>

          <el-form-item :label="$t('Tags')" prop="tags">
            <el-input size="small" v-model="row.tags"/>
          </el-form-item>

          <el-form-item :label="$t('ICP Number')" prop="keyAccount">
            <el-input size="small" v-model="row.icpNumber" maxlength="32"/>
          </el-form-item>

          <el-form-item :label="$t('Credit Amount Alert Emails')">
            <el-input size="small" v-model="row.creditAmountAlertEmails" placeholder="Email addresses separated by ,"/>
          </el-form-item>

          <el-form-item :label="$t('Contract End Alert Emails')">
            <el-input size="small" v-model="row.contractEndAlertEmails" placeholder="Email addresses separated by ,"/>
          </el-form-item>

          <el-form-item :label="$t('Stakeholder Required')">
            <div style="clear: both">
              <el-checkbox size="small" v-model="row.stakeholderRequired"/>
            </div>
          </el-form-item>
        </b-col>
      </b-row>
      </el-form>
    </div>

    <div class="my-2" v-if="step === 1">
      <h3>
        Company Contacts
        <el-button size="mini" type="success" class="float-right" @click="addCompanyContact()"><i class="el-icon-plus"></i>  Add Company Contact</el-button>
      </h3>

      <el-card class="box-card mb-2" v-for="(companyContact, k) in row.companyContacts" :key="'cc' + k">
        <b-row>
          <b-col lg="3">
            <b-form-group :label="$t('Name')">
              <template v-slot:label="scope">
                <span class="required" v-if="companyContact.isRequired">*</span>
                {{ $t('Name') }}
              </template>
              <el-input size="small" v-model="companyContact.name" maxlength="255"/>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group :label="$t('Phone')">
              <template v-slot:label="scope">
                <span class="required" v-if="companyContact.isRequired">*</span>
                {{ $t('Phone') }}
              </template>
              <el-input size="small" v-model="companyContact.phone" maxlength="32"/>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group :label="$t('Email')">
              <template v-slot:label="scope">
                <span class="required" v-if="companyContact.isRequired">*</span>
                {{ $t('Email') }}
              </template>
              <el-input type="email" size="small" v-model="companyContact.email" maxlength="255"/>
            </b-form-group>
          </b-col>

          <b-col lg="1">
            <el-button type="danger" class="mt-1" v-if="!companyContact.isRequired && !companyContact.id" @click="removeCompanyContact(k)"><i class="el-icon-delete"></i> Remove</el-button>
            <el-button type="danger" class="mt-1" v-if="!companyContact.isRequired && companyContact.id" @click="removeCompanyContactRemote(k, companyContact)"><i class="el-icon-delete"></i> Remove</el-button>
          </b-col>
        </b-row>
      </el-card>

      <hr/>

      <h3>
        F&amp;A Contacts
        <el-button size="mini" type="success" class="float-right" @click="addFaContact()"><i class="el-icon-plus"></i>  Add F&amp;A Contact</el-button>
      </h3>
      <el-card class="" v-for="(faContact, k) in row.faContacts" :key="'fc-' + k">
        <b-row>
          <b-col lg="3">
            <b-form-group :label="$t('Name')">
              <template v-slot:label="scope">
                <span class="required" v-if="faContact.isRequired">*</span>
                {{ $t('Name') }}
              </template>
              <el-input size="small" v-model="faContact.name" maxlength="255"/>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group :label="$t('Phone')">
              <template v-slot:label="scope">
                <span class="required" v-if="faContact.isRequired">*</span>
                {{ $t('Phone') }}
              </template>
              <el-input size="small" v-model="faContact.phone" maxlength="32"/>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group :label="$t('Email')">
              <template v-slot:label="scope">
                <span class="required" v-if="faContact.isRequired">*</span>
                {{ $t('Email') }}
              </template>
              <el-input type="email" size="small" v-model="faContact.email" maxlength="255"/>
            </b-form-group>
          </b-col>

          <b-col lg="1">
            <el-button type="danger" class="mt-1" v-if="!faContact.isRequired && !faContact.id" @click="removeFaContact(k)"><i class="el-icon-delete"></i> Remove</el-button>
            <el-button type="danger" class="mt-1" v-if="!faContact.isRequired && faContact.id" @click="removeFaContactRemote(k, faContact)"><i class="el-icon-delete"></i> Remove</el-button>
          </b-col>
        </b-row>
      </el-card>
    </div>

    <div class="my-2" v-if="step === 2">
      <el-card class="" v-for="(document, k) in row.documents" :key="k">
        <b-row>
          <b-col lg="3">
            <b-form-group :label="$t('Document Type')">
              <template v-slot:label="scope">
                <span class="required" v-if="document.isRequired">*</span>
                {{ $t('Document Type') }}
              </template>
              <el-input size="small" v-model="document.type" :readonly="document.isRequired" maxlength="255"/>
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <b-form-group :label="$t('Valid Date')">
              <el-input size="small" type="number" min="0" max="3650" v-model="document.validDays"/>
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <b-form-group>
              <el-checkbox class="mt-2" v-model="document.isSubsequentDocument">Subsequent Documents</el-checkbox>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group>
              <el-button v-if="document.clientFilename !== null" class="mt-1 text-ellipsis-button" @click="openDocument(document)">{{ document.clientFilename }}</el-button>
              <el-button v-if="document.clientFilename === null" class="mt-1" @click="uploadDocument(k)">Upload File</el-button>
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <el-button type="info" v-if="document.clientFilename !== null" class="mt-1" @click="uploadDocument(k)"><i class="el-icon-refresh"></i> Replace</el-button>
            <el-button type="danger" class="mt-1" v-if="!document.id" @click="removeDocument(k)"><i class="el-icon-delete"></i> Remove</el-button>
            <el-button type="danger" class="mt-1" v-if="document.id" @click="removeDocumentRemote(k, document)"><i class="el-icon-delete"></i> Remove</el-button>
          </b-col>
        </b-row>
      </el-card>
    </div>

    <div class="mt-2" v-if="step === 3">
      <b-row>
        <b-col lg="4">
          <el-card class="box-card mb-2">
            <h4>Select Charge Code</h4>
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item :title="k1" :name="k1" v-for="(firstLevel, k1) in sortTree(chargeCodeTree)" :key="k1">
                <div class="charge-code-level" v-for="(secondLevel, k2) in sortTree(firstLevel)" :key="k2">
                  <h5>{{ k2 }}</h5>
                  <div class="charge-code-level" v-for="(thirdLevel, k3) in sortTree(secondLevel)" :key="k3">
                    <h6>{{ k3 }}</h6>
                    <div class="charge-code-level" v-for="(forthLevel, k4) in sortTree(thirdLevel)" :key="k4">
                      <el-checkbox v-model="row.chargeCodes" :label="forthLevel.code" :key="forthLevel.code">{{ forthLevel.code }} - {{ forthLevel.remark }}</el-checkbox>
                    </div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-card>
        </b-col>
        <b-col lg="8">
          <el-card class="charge-codes box-card mb-2">
            <table style="width: 100%">
              <tr>
                <th class="py-1">Category</th>
                <th class="py-1">Description</th>
                <th class="py-1">Charge Code</th>
                <th class="py-1">Actions</th>
              </tr>
              <tr v-for="chargeCode in row.chargeCodes">
                <td class="py-1">{{ chargeCodeMap[chargeCode].category }}</td>
                <td class="py-1">{{ chargeCodeMap[chargeCode].level1 }} &gt; {{ chargeCodeMap[chargeCode].level2 }}</td>
                <td class="py-1">{{ chargeCodeMap[chargeCode].code }}</td>
                <td class="py-1"><el-link @click="removeCode(chargeCode)"><i class="el-icon-delete"></i> Remove</el-link></td>
              </tr>
            </table>
          </el-card>
        </b-col>
      </b-row>
    </div>

    <div class="mt-2" v-if="step === 4">
      <el-form :model="row" ref="previewForm" class="compact-no-validation">
      <b-card>
        <b-row>
          <b-col lg="6">
            <h3>
              Company Detail
            </h3>
            <b-row>
              <b-col lg="6">
                <el-form-item :label="$t('Company Name (Invoice)')">
                  <el-input size="mini" v-model="row.companyName" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Company Name (System)')">
                  <el-input size="mini" v-model="row.companyNameInternal" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Company Address')">
                  <el-input size="mini" v-model="row.companyAddress" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('BR Number (For HK Company)')">
                  <el-input size="mini" v-model="row.brNumber" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Company Type')">
                  <el-input size="mini" v-model="row.companyType" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Payment Terms (Days)')">
                  <el-input size="mini" v-model="row.paymentTermDays" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Key Account')">
                  <el-input size="mini" v-model="row.keyAccount" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Credit Amount')">
                  <el-input size="small" v-model="row.creditAmount" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Contract End Date')">
                  <el-input size="small" v-model="row.contractEndDate" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Contract Inactive')">
                  <div style="clear: both">
                    <el-checkbox size="small" v-model="row.contractEndInactive" disabled/>
                  </div>
                </el-form-item>

                <el-form-item :label="$t('Service Code')">
                  <el-input size="mini" v-model="row.serviceCode" readonly="readonly"/>
                </el-form-item>
              </b-col>

              <b-col lg="6">
                <el-form-item :label="$t('ERP Account Code')">
                  <el-input size="mini" v-model="row.accountCode" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Country')">
                  <el-input size="mini" v-model="row.country" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Corporate Registration Number')">
                  <el-input size="mini" v-model="row.corporateRegistrationNumber" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Contract Type')">
                  <el-input size="mini" v-model="row.contractType" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Payment Currency')">
                  <el-input size="mini" v-model="row.paymentCurrency" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Tags')">
                  <el-input size="mini" v-model="row.tags" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('ICP Number')">
                  <el-input size="mini" v-model="row.icpNumber" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Credit Amount Alert Emails')">
                  <el-input size="small" v-model="row.creditAmountAlertEmails" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Contract End Alert Emails')">
                  <el-input size="mini" v-model="row.contractEndAlertEmails" readonly="readonly"/>
                </el-form-item>

                <el-form-item :label="$t('Stakeholder Required')">
                  <div style="clear: both">
                    <el-checkbox size="small" v-model="row.stakeholderRequired" readonly="readonly"/>
                  </div>
                </el-form-item>
              </b-col>
            </b-row>

            <hr/>

            <h3>
              Company Contacts
            </h3>

            <div class="box-card mb-2" v-for="(companyContact, k) in row.companyContacts" :key="'cc' + k">
              <b-row>
                <b-col lg="4">
                  <b-form-group :label="$t('Name')">
                    <template v-slot:label="scope">
                      <span class="required" v-if="companyContact.isRequired">*</span>
                      {{ $t('Name') }}
                    </template>
                    <el-input size="mini" v-model="companyContact.name" readonly="readonly"/>
                  </b-form-group>
                </b-col>

                <b-col lg="4">
                  <b-form-group :label="$t('Phone')">
                    <template v-slot:label="scope">
                      <span class="required" v-if="companyContact.isRequired">*</span>
                      {{ $t('Phone') }}
                    </template>
                    <el-input size="mini" v-model="companyContact.phone" readonly="readonly"/>
                  </b-form-group>
                </b-col>

                <b-col lg="4">
                  <b-form-group :label="$t('Email')">
                    <template v-slot:label="scope">
                      <span class="required" v-if="companyContact.isRequired">*</span>
                      {{ $t('Email') }}
                    </template>
                    <el-input size="mini" v-model="companyContact.email" readonly="readonly"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <hr/>

            <h3>
              F&amp;A Contacts
            </h3>
            <div class="box-card mb-2" v-for="(faContact, k) in row.faContacts" :key="'fc' + k">
              <b-row>
                <b-col lg="4">
                  <b-form-group :label="$t('Name')">
                    <template v-slot:label="scope">
                      <span class="required" v-if="faContact.isRequired">*</span>
                      {{ $t('Name') }}
                    </template>
                    <el-input size="mini" v-model="faContact.name" readonly="readonly"/>
                  </b-form-group>
                </b-col>

                <b-col lg="4">
                  <b-form-group :label="$t('Phone')">
                    <template v-slot:label="scope">
                      <span class="required" v-if="faContact.isRequired">*</span>
                      {{ $t('Phone') }}
                    </template>
                    <el-input size="mini" v-model="faContact.phone" readonly="readonly"/>
                  </b-form-group>
                </b-col>

                <b-col lg="4">
                  <b-form-group :label="$t('Email')">
                    <template v-slot:label="scope">
                      <span class="required" v-if="faContact.isRequired">*</span>
                      {{ $t('Email') }}
                    </template>
                    <el-input size="mini" v-model="faContact.email" readonly="readonly"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-col>


          <b-col lg="6">
            <h3>
              Documents
            </h3>

            <div v-for="(document, k) in row.documents" :key="k">
              <b-row>
                <b-col lg="3">
                  <b-form-group :label="$t('Document Type')">
                    <template v-slot:label="scope">
                      <span class="required" v-if="document.isRequired">*</span>
                      {{ $t('Document Type') }}
                    </template>
                    <el-input size="mini" v-model="document.type" :readonly="document.isRequired" readonly="readonly"/>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-form-group :label="$t('Valid Date')">
                    <el-input size="mini" type="number" min="0" max="3650" v-model="document.validDays" readonly="readonly"/>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-form-group>
                    <el-checkbox class="mt-2" size="mini" v-model="document.isSubsequentDocument" disabled="disabled">Subsequent</el-checkbox>
                  </b-form-group>
                </b-col>
                <b-col lg="5" class="text-right">
                  <b-form-group>
                    <el-button class="mt-1 text-ellipsis-button" size="mini" readonly="readonly" @click="openDocument(document)">{{ document.clientFilename ? document.clientFilename : 'No File' }}</el-button>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr/>
            </div>

            <h3>
              Charge Codes
            </h3>

            <table style="width: 100%">
              <tr>
                <th class="py-1">Category</th>
                <th class="py-1">Description</th>
                <th class="py-1">Charge Code</th>
              </tr>
              <tr v-for="chargeCode in row.chargeCodes" :key="chargeCode.code">
                <td class="py-1">{{ chargeCodeMap[chargeCode].category }}</td>
                <td class="py-1">{{ chargeCodeMap[chargeCode].remark }}</td>
                <td class="py-1">{{ chargeCodeMap[chargeCode].code }}</td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
      </el-form>
    </div>

    <div class="text-right">
      <el-button icon="el-icon-plus" type="primary" @click="addExtraDocument()" v-if="step === 2" style="float: left;">Add Extra</el-button>
      <el-button type="info" @click="saveDraft()" v-if="!row.id">Save as Draft</el-button>
      <el-button icon="el-icon-arrow-left" type="primary" @click="previousStep(step)" v-if="step > 0">Back</el-button>
      <el-button icon="el-icon-arrow-right" type="primary" @click="nextStep(step)" v-if="step <= 3">Next</el-button>
      <el-button icon="el-icon-arrow-right" type="success" @click="complete(step)" v-if="step > 3 || row.id">Complete</el-button>
    </div>
  </div>

  <pre v-if="isDebug">{{ row }}</pre>

  <UploadModal ref="uploadView"></UploadModal>
</div>
</template>

<script>
import { getAccessToken, getErpProfile } from "@/utils/account-localstorage";
import { BButton, BModal } from "bootstrap-vue";
import apis from "@/configs/apis";
import { sortTree } from "@/views/erp/utils/object";
import { EventBus } from "@/views/erp/utils/event-bus";
import UploadModal from "@/views/erp/components/UploadModal.vue";
import { validateEmail } from "@/views/erp/utils/email";
import File from "@/views/erp/mixins/File";
import Prompt from "@/views/erp/mixins/Prompt";
import axios from "axios";

export default {
  mixins: [File, Prompt],
  components: {
    UploadModal,
    BModal, BButton
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      step: 0,
      isLoading: false,

      uploadIndex: null,
      uploadTitle: '',
      rules: {
        companyName: [
          { required: true, message: 'Please input Company Name (Invoice)', trigger: 'blur' }
        ],
        companyAddress: [
          { required: true, message: 'Please input Company Address', trigger: 'blur' }
        ],
        paymentTermDays: [
          { required: true, message: 'Please input Payment Term Days', trigger: 'blur' }
        ],
        paymentCurrency: [
          { required: true, message: 'Please input Payment Currency', trigger: 'blur' }
        ],
        accountCode: [
          { required: true, message: 'Please input Account Code', trigger: 'blur' }
        ],
      },
      row: {
        companyName: null,
        companyNameInternal: null,
        companyAddress: null,
        contactPerson: null,
        contactNumber: null,
        brNumber: null,
        companyType: 'External',
        paymentTermDays: 30,
        keyAccount: null,
        accountCode: null,
        country: null,
        contactEmail: null,
        faContactEmail: null,
        corporateRegistrationNumber: null,
        contractType: null,
        paymentCurrency: 'HKD',
        tags: null,
        icpNumber: null,
        documents: [
          {
            type: 'Valid Business Registration Certificate',
            validDays: 12,
            isSubsequentDocument: false,
            serverFilename: null,
            clientFilename: null,
            isRequired: true,
          },
          {
            type: 'Signed Contract',
            validDays: 12,
            isSubsequentDocument: false,
            serverFilename: null,
            clientFilename: null,
            isRequired: true,
          },
          {
            type: 'Application Form',
            validDays: 12,
            isSubsequentDocument: false,
            serverFilename: null,
            clientFilename: null,
            isRequired: true,
          }
        ],
        companyContacts: [],
        faContacts: [],
        chargeCodes: []
      },
      activeName: 'Fulfillment',
      chargeCodeMap: {},
      chargeCodeTree: {},
      chargeCodeLoaded: false,
      draftId: null,
      isDebug: false,
      serviceCodeOptions: [null, 'DISPA', 'MEC']
    };
  },
  computed: {
    apis() {
      return apis
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loadChargeCodes();
    this.addCompanyContact();
    this.addFaContact();

    // Load existing profile
    if (this.$route.params.id !== undefined) {
      const id = this.$route.params.id;
      console.log('Load existing: ' + id);
      this.loadExisting(id);
    } else if (localStorage.getItem('draft')) {
      try {
        console.log('Load draft');
        this.loadDraft();
      } catch (e) {
        console.log('Load draft error: ' + e.message);
      }
    }
  },
  methods: {
    sortTree,
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    getAccessToken,

    previousStep() {
      this.step -= 1;
    },

    nextStep() {
      if (this.step === 0) {
        this.$refs.mainForm.validate()
          .then(() => {
            // Intercom do not require documents
            if (this.row.companyType === 'InterCom') {
              this.row.documents.forEach(row => {
                row.isRequired = false;
              });
            }

            this.step += 1;
          })
          .catch((errors) => {
            let errorMessages = [];
            for (const i in errors) {
              for (const j in errors[i]) {
                errorMessages.push(errors[i][j]['message']);
              }
            }

            this.promptError(errorMessages.join(', '));
          });

      } else if (this.step === 1) {
        let errorMessages = [];
        this.row.companyContacts.forEach(row => {
          if ((!row.name || !row.phone || !row.email) && row.isRequired) {
            errorMessages.push("Company Contact " + row.name + ' is required');
          }

          if (row.email && !validateEmail(row.email)) {
            errorMessages.push("Company Contact Email " + row.email + ' is invalid');
          }
        });

        this.row.faContacts.forEach(row => {
          if ((!row.name || !row.phone || !row.email) && row.isRequired) {
            errorMessages.push("F&A Contact " + row.name + ' is required');
          }

          if (row.email && !validateEmail(row.email)) {
            errorMessages.push("F&A Contact Email " + row.email + ' is invalid');
          }
        });

        if (errorMessages.length > 0) {
          this.promptError(errorMessages.join(', '));
          return;
        }

        this.step += 1;
      } else if (this.step === 2) {
        let errorMessages = [];
        this.row.documents.forEach(row => {
          if (row.serverFilename === null && row.isRequired && !row.isSubsequentDocument) {
            errorMessages.push(row.type + ' is required');
          }
        });

        if (errorMessages.length > 0) {
          this.promptError(errorMessages.join(', '));
          return;
        }

        this.step += 1;
      } else if (this.step === 3) {
        this.step += 1;
      }
    },

    async complete() {
      try {
        if (this.row.id) await this.saveExisting();
        else await this.saveNew();
      } catch (e) {
        this.promptError(e);
      }
    },

    async loadDraft() {
      let draft = localStorage.getItem("draft");
      let draftId = localStorage.getItem("draft_id");

      if (draft !== null && draft !== 'null') {
        this.row = JSON.parse(draft);
        this.draftId = draftId;
      }

      localStorage.setItem("draft", null);
      localStorage.setItem("draft_id", null);
    },

    async saveDraft() {
      axios.post(apis.erpOrganizationProfileDraft, this.row)
        .then(() => {
          this.promptInfo("Draft saved!");

          this.$router.push({
            name: "erp-organisation-profile",
            params: {
            },
          });
        }).catch(e => {
          this.promptError(e);
        });
    },

    addCompanyContact() {
      this.row.companyContacts.push({
        name: "",
        phone: "",
        email: "",
        isRequired: false
      });
    },

    removeCompanyContact(index) {
      this.row.companyContacts.splice(index, 1);
    },

    addFaContact() {
      this.row.faContacts.push({
        name: "",
        phone: "",
        email: "",
        isRequired: false
      });
    },

    removeFaContact(index) {
      this.row.faContacts.splice(index, 1);
    },

    async removeCompanyContactRemote(index, contact) {
      axios.delete(
        apis.erpOrganizationProfile + '/' + this.row.id + '/contact/' + contact.id,
      ).then(() => {
        this.row.companyContacts.splice(index, 1);
      }).catch(e => {
        this.promptError(e);
      });
    },

    async removeFaContactRemote(index, contact) {
      axios.delete(
        apis.erpOrganizationProfile + '/' + this.row.id + '/contact/' + contact.id
      ).then(() => {
        this.row.faContacts.splice(index, 1);
      }).catch(e => {
        this.promptError(e);
      });
    },

    async removeDocumentRemote(index, document) {
      axios.delete(
        apis.erpOrganizationProfile + '/' + this.row.id + '/document/' + document.id,
      ).then(() => {
        this.row.documents.splice(index, 1);
      }).catch(e => {
        this.promptError(e);
      });
    },

    uploadDocument(index) {
      this.$refs.uploadView.showUpload('Upload ' + this.row.documents[index].type, 'ORGANIZATION_PROFILE', {maxSize: 104857600}, (form) => {
        this.isLoading = true;

        this.row.documents[index].clientFilename = form.clientFilename;
        this.row.documents[index].serverFilename = form.serverFilename;

        this.promptInfo("'" + this.row.documents[index].type + "' Upload Success");

        this.$refs.uploadView.hideUpload();

        this.isLoading = false;
      });
    },

    removeDocument(index) {
      this.row.documents.splice(index, 1);
    },

    openDocument(document) {
      this.downloadFile('ORGANIZATION_PROFILE', document.serverFilename, document.clientFilename);
    },

    addExtraDocument() {
      this.row.documents.push({
        type: '',
        validDays: 12,
        isSubsequentDocument: false,
        serverFilename: null,
        clientFilename: null,
        isRequired: false,
      });
    },

    removeCode(value) {
      this.row.chargeCodes = this.row.chargeCodes.filter(function(item) {
        return item !== value
      })
    },

    async loadExisting(id) {
      axios.get(apis.erpOrganizationProfile + '/' + id)
        .then(response => {
          this.row = response.data.data;

          // Update breadcrumb
          this.$route.meta.breadcrumb[2].text = 'Edit ' + this.row.companyName;
          EventBus.$emit("route-meta-updated");
        });
    },

    async saveNew() {
      axios.post(
        apis.erpOrganizationProfile,
        this.row
      ).then(response => {
        this.promptInfo('Organisation Profile created successfully')

        // Delete the draft on complete
        if (this.draftId) {
            axios.delete(
              apis.erpOrganizationProfileDraft + '/' + this.draftId
            )
        }

        this.$router.push({
          name: "erp-organisation-profile",
        })
      });
    },

    async saveExisting() {
      axios.put(
        apis.erpOrganizationProfile + '/' + this.row.id,
        this.row
      ).then(() => {
        this.promptInfo('Organisation Profile updated successfully')

        this.$router.push({
          name: "erp-organisation-profile",
        })
      });
    },

    async loadChargeCodes() {
      axios.get(
        apis.erpChargeCodes,
      ).then(response => {
        let map = {};
        let tree = {};

        response.data.data.data.forEach(row => {
          if (typeof tree[row.category] === "undefined") tree[row.category] = {};
          if (typeof tree[row.category][row.level1] === "undefined") tree[row.category][row.level1] = {};
          if (typeof tree[row.category][row.level1][row.level2] === "undefined") tree[row.category][row.level1][row.level2] = [];

          map[row.code] = row;
          tree[row.category][row.level1][row.level2].push(row);
        })

        this.chargeCodeMap = map;
        this.chargeCodeTree = tree;
      });
    },

    canGoToStep(step) {
      return this.step > step || this.row.id;
    },

    goToStep(step) {
      // For unsaved item, can only go back
      if (this.canGoToStep(step)) this.step = step;
    },

    debugMode() {
      this.isDebug = true;
    }
  }
};
</script>

<style scoped>
.charge-code-level {
  padding-left: 16px;
}
</style>
